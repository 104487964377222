import { useToast } from 'vue-toastification';
import types from '../mutation-types';
import { initialStepTime } from 'src/config/step-time-config.js';
import { modes } from 'src/config/task-edit-modes-config.js';
import { objectAnnotationModes } from 'src/config/task-steps-objects.js';
import TaskService from 'src/services/tasks';
import DeviceService from 'src/services/device';
import DepthRegionService from 'src/services/3dPlaneEquation';
import trainingStatuses from 'src/config/training-status-config.js';
import { getRandomColor, validateColor } from 'src/config/color-config';
import {
  deepClone,
  validateForSpecialCharacters,
  checkIfTaskExist,
  checkIfEmpty,
  checkIfSubstepsExists,
  isDuplicateProcess,
  isDuplicateStep,
  isDuplicateSubstep,
  hasNoProcess,
  hasNoSteps,
  getTaskString,
  startTaskModification,
  getStepsCount,
  getDestinationIndex,
  getTaskNameAndProcess,
  isTaskBeingTrained,
  getTaskUpdatePayload,
  initializeTranslation,
  updateSimilarStepsFileS3,
  isTranslationNotExist,
  getIndexToStepMapping,
  getStepToIndexMapping,
  updateTaskObjectRules,
  convertStepTimeValuesToNumber,
  getTaskUpdateJson,
  isStepAndSubstepSame,
  getPrevStepsJson,
  getUpdatedTasksList,
  updatedAttributeSteps,
  getSubstepToStepMapping,
  isTaskBeingPerformed,
  getProcessToStepsMap,
  initAnnotationObjectsJson,
  getIdToObjectMapping,
  getObjectToIdMapping,
  getTranslatedSteps,
  hasMorethanTwoProcess,
  getTaskParameters,
  setSubStepTimesJsonFile,
  getUpdatedTaskJson,
  sendTaskUpdateNotification,
} from 'src/utils/task';

const toast = useToast();

const state = {
  allTasks: [],
  trainedTasks: [],
  selectedTask: null,
  isTask3d: false,
  modelChoice: '',
  taskName: '',
  taskDetails: '',
  taskStepsCount: 0,
  isTaskStarred: false,
  isMultiProcessTask: false,
  processToStepListMap: {},
  taskJson: {},
  isAddingTranslation: false,
  isDeletingTranslation: false,
  isTranslation: false,
  isTranslationEdit: false,
  translatedTaskName: '',
  translationDetails: '',
  translationLanguage: '',
  processesTranslation: [],
  selectedLang: 'English',
  negativeSteps: [],
  taskProcesses: [],
  newTask: '',
  taskString: '',
  isTaskEdit: false,
  processes: [],
  currentEditMode: '',
  editModes: { ...modes },
  prevStepsJson: {},
  updatedJsonPayload: [],
  resJsonForAddDeletePhase: [],
  resJsonForRenamePhase: [],
  taskUpdateConfirmModal: false,
  isFetchingTasks: false,
  isFetchingTaskDetails: false,
  isFetchingTaskStatus: false,
  isAddingTask: false,
  isUpdatingTaskFields: false,
  similarSteps: {},
  showSimilarStepsModal: false,
  isUpdatingSimilarSteps: false,
  taskObjects: [],
  taskObjectRules: [],
  indexToStepsMapping: {},
  stepsToIndexMapping: {},
  isSavingObjectDetectionRule: false,
  showStepTimeModal: false,
  stepTime: { ...initialStepTime },
  isUpdatingStepTime: false,
  isStepTimeExist: false,
  showTaskParamsModal: false,
  isUpdatingTaskParams: false,
  taskParameters: {},
  isUpdateTaskFromAdmin: false,
  taskOrganization: localStorage.getItem('organization'),
  showRegionDefinitionModal: false,
  showAssociateRegionModal: false,
  taskStaticObjectsList: [],
  taskPlaneObjectsList: [],
  taskRegionMarkingImgUrl: '',
  optionalSteps: [],
  stepToAttributesMapping: {},
  mergeStepsDict: {},
  subStepToStepMapping: {},
  showModelVersionsModal: false,
  taskAssociatedModelVersion: null,
  isUpdatingAssociateModelVersion: false,
  stepsJsonData: {},
  subStepTimesJson: {},
  showAnnotationObjectsModal: false,
  annotationObjectJson: {},
  currentAnnotationObjectMode: objectAnnotationModes['annotation'],
  objectWarnings: {},
  idToObjectMapping: {},
  objectToIdMapping: {},
  objectDetectionRuleUpdatedData: [],
  stepsTranslationMapping: {},
  showODSetupModal: false,
  stepsAssociationIds: new Set(),
  showProjectorRegionModal: false,
  planeObjectsList: [],
  taskStaticAnnotationImage: null,
  maxCycleTime: Number.MAX_SAFE_INTEGER,
  showUploadMediaModal: false,
  taskStepsMediaObject: {},
  rectificationSteps: [],
  taskCreatedAt: null,
  isMissedStepsSelected: false,
};

const getters = {
  allTasks: (state) => state.allTasks,
  isFetchingTasks: (state) => state.isFetchingTasks,
  isFetchingTaskDetails: (state) => state.isFetchingTaskDetails,
  isFetchingTaskStatus: (state) => state.isFetchingTaskStatus,
  isAddingTask: (state) => state.isAddingTask,
  isUpdatingTaskFields: (state) => state.isUpdatingTaskFields,
  trainedTasks: (state) => state.trainedTasks,
  selectedTask: (state) => state.selectedTask,
  isTask3d: (state) => state.isTask3d,
  modelChoice: (state) => state.modelChoice,
  taskName: (state) => state.taskName,
  taskDetails: (state) => state.taskDetails,
  taskStepsCount: (state) => state.taskStepsCount,
  isTaskStarred: (state) => state.isTaskStarred,
  isMultiProcessTask: (state) => state.isMultiProcessTask,
  processToStepListMap: (state) => state.processToStepListMap,
  taskJson: (state) => state.taskJson,
  isAddingTranslation: (state) => state.isAddingTranslation,
  isDeletingTranslation: (state) => state.isDeletingTranslation,
  isTranslation: (state) => state.isTranslation,
  isTranslationEdit: (state) => state.isTranslationEdit,
  translatedTaskName: (state) => state.translatedTaskName,
  translationDetails: (state) => state.translationDetails,
  translationLanguage: (state) => state.translationLanguage,
  processesTranslation: (state) => state.processesTranslation,
  selectedLang: (state) => state.selectedLang,
  negativeSteps: (state) => state.negativeSteps,
  taskProcesses: (state) => state.taskProcesses,
  newTask: (state) => state.newTask,
  isTaskEdit: (state) => state.isTaskEdit,
  processes: (state) => state.processes,
  taskUpdateConfirmModal: (state) => state.taskUpdateConfirmModal,
  similarSteps: (state) => state.similarSteps,
  showSimilarStepsModal: (state) => state.showSimilarStepsModal,
  isUpdatingSimilarSteps: (state) => state.isUpdatingSimilarSteps,
  taskObjects: (state) => state.taskObjects,
  taskObjectRules: (state) => state.taskObjectRules,
  stepsToIndexMapping: (state) => state.stepsToIndexMapping,
  isSavingObjectDetectionRule: (state) => state.isSavingObjectDetectionRule,
  showStepTimeModal: (state) => state.showStepTimeModal,
  stepTime: (state) => state.stepTime,
  avgCycleTime: (state) => state.stepTime.avgCycleTime,
  stepAvgTime: (state) => (step) => state.stepTime.stepAvgTime[step] || '',
  isUpdatingStepTime: (state) => state.isUpdatingStepTime,
  isStepTimeExist: (state) => state.isStepTimeExist,
  showTaskParamsModal: (state) => state.showTaskParamsModal,
  isUpdatingTaskParams: (state) => state.isUpdatingTaskParams,
  taskParameters: (state) => state.taskParameters,
  taskCreatedAt: (state) => state.taskCreatedAt,
  isMissedStepsSelected: (state) => state.isMissedStepsSelected,

  steps: (state) => (processIndex) =>
    state.taskProcesses[processIndex]['steps'],

  newSteps: (state) => (processIndex) => state.processes[processIndex]['steps'],

  stepsTranslation: (state) => (processIndex) =>
    state.processesTranslation[processIndex]['steps'],

  substeps: (state) => (processIndex, stepIndex) =>
    state.taskProcesses[processIndex]['steps'][stepIndex]['substeps'],

  newSubsteps: (state) => (processIndex, stepIndex) =>
    state.processes[processIndex]['steps'][stepIndex]['substeps'],

  substepsTranslation: (state) => (processIndex, stepIndex) =>
    state.processesTranslation[processIndex]['steps'][stepIndex]['substeps'],

  stepsCount: (state) => (processIndex) =>
    state.taskProcesses[processIndex]?.steps.length,

  substepsCount: (state) => (processIndex, stepIndex) =>
    state.taskProcesses[processIndex]?.steps[stepIndex]?.substeps.length,

  currentEditMode: (state) => state.currentEditMode,
  editModes: (state) => state.editModes,
  prevStepsJson: (state) => state.prevStepsJson,
  updatedJsonPayload: (state) => state.updatedJsonPayload,
  indexToStepsMapping: (state) => state.indexToStepsMapping,
  isUpdateTaskFromAdmin: (state) => state.isUpdateTaskFromAdmin,
  taskOrganization: (state) => state.taskOrganization,
  showRegionDefinitionModal: (state) => state.showRegionDefinitionModal,
  showAssociateRegionModal: (state) => state.showAssociateRegionModal,
  taskStaticObjectsList: (state) => state.taskStaticObjectsList,
  taskPlaneObjectsList: (state) => state.taskPlaneObjectsList,
  taskRegionMarkingImgUrl: (state) => state.taskRegionMarkingImgUrl,
  optionalSteps: (state) => state.optionalSteps,
  stepToAttributesMapping: (state) => state.stepToAttributesMapping,
  mergeStepsDict: (state) => state.mergeStepsDict,
  subStepToStepMapping: (state) => state.subStepToStepMapping,
  stepsJsonData: (state) => state.stepsJsonData,
  subStepTimesJson: (state) => state.subStepTimesJson,
  showModelVersionsModal: (state) => state.showModelVersionsModal,
  taskAssociatedModelVersion: (state) => state.taskAssociatedModelVersion,
  isUpdatingAssociateModelVersion: (state) =>
    state.isUpdatingAssociateModelVersion,
  stepsTranslationMapping: (state) => state.stepsTranslationMapping,
  stepsAssociationIds: (state) => state.stepsAssociationIds,
  showAnnotationObjectsModal: (state) => state.showAnnotationObjectsModal,
  currentAnnotationObjectMode: (state) => state.currentAnnotationObjectMode,
  annotationObjectJson: (state) => state.annotationObjectJson,
  objectWarnings: (state) => state.objectWarnings,
  idToObjectMapping: (state) => state.idToObjectMapping,
  objectToIdMapping: (state) => state.objectToIdMapping,
  showODSetupModal: (state) => state.showODSetupModal,
  showProjectorRegionModal: (state) => state.showProjectorRegionModal,
  planeObjectsList: (state) => state.planeObjectsList,
  taskStaticAnnotationImage: (state) => state.taskStaticAnnotationImage,
  outLierLimit: (state) => state.maxCycleTime,
  showUploadMediaModal: (state) => state.showUploadMediaModal,
  taskStepsMediaObject: (state) => state.taskStepsMediaObject,
  rectificationSteps: (state) => state.rectificationSteps,
};

const actions = {
  setIsMissedStepsSelected({ commit }, payload) {
    commit(types.SET_MISSED_STEP_SELECTED, payload);
  },

  async getAllTasks({ commit }) {
    commit(types.SET_IS_FETCHING_TASKS, true);
    const [error, data] = await TaskService.fetchTasks(false);
    commit(types.SET_IS_FETCHING_TASKS, false);
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_TASKS, data);
  },

  async getTrainedTasks({ commit }, trainedStatus) {
    //  || trainingStatuses.trained
    const [error, tasks] = await TaskService.fetchTrainedTasksByStatus(
      trainedStatus,
      false
    );
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_TRAINED_TASKS, tasks);
  },

  setSelectedTask({ commit, dispatch }, taskId) {
    commit(types.SET_SELECTED_TASK, taskId);
    dispatch('resetProcessState');
    dispatch('resetTranslationState');
    dispatch('resetStepTimeState');
  },

  setTasks({ commit }, tasks) {
    commit(types.SET_TASKS, tasks);
  },

  setTaskDetail({ commit }, task_detail) {
    commit(types.SET_TASK_DETAILS, task_detail);
  },

  setIsTaskStarred({ commit }, value) {
    commit(types.SET_IS_TASK_STARRED, value);
  },

  setTaskOrganization({ commit }, organization) {
    commit(types.SET_TASK_ORGANIZATION, organization);
  },

  clearTaskDetails({ commit }) {
    commit(types.SET_SELECTED_TASK, null);
    commit(types.SET_TASK_DETAILS, '');
    commit(types.SET_TASK_NAME, '');
    commit(types.SET_TASK_PROCESSES, []);
    commit(types.SET_NEGATIVE_STEPS, []);
    commit(types.SET_TRANSLATION_DETAILS, '');
    commit(types.SET_TRANSLATION_LANGUAGE, '');
    commit(types.SET_SELECTED_LANGUAGE, 'English');
  },

  resetTaskCreatedAt({ commit }) {
    commit(types.SET_TASK_CREATED_AT, null);
  },

  async fetchTaskDetails({ commit, dispatch }, taskId) {
    commit(types.SET_IS_FETCHING_TASK_DETAILS, true);
    const [error, data] = await TaskService.fetchTaskDetails(taskId, false);
    commit(types.SET_IS_FETCHING_TASK_DETAILS, false);
    if (error) {
      console.log({ error });
      return;
    }
    const {
      task_detail,
      stepsCount,
      taskJson,
      translation,
      negativeSteps,
      translation_name,
      similarSteps: ss,
      stepsAvgTime,
      starred,
      optional_steps,
      model_choice,
      model_version,
      steps_data_json,
      sub_step_times,
      annotation_image_id,
      maximum_cycle_time,
      created_at,
    } = data;
    const similar_steps =
      typeof ss === 'object' ? (ss === null ? {} : ss) : JSON.parse(ss);
    const taskParameters = getTaskParameters(data);
    const stepsJsonData =
      typeof steps_data_json === 'string'
        ? JSON.parse(steps_data_json)
        : steps_data_json;
    commit(types.SET_TASK_DETAILS, task_detail);
    commit(types.SET_TASK_STEPS_COUNT, stepsCount);
    commit(types.SET_TASK_JSON, taskJson);
    commit(types.SET_NEGATIVE_STEPS, negativeSteps);
    commit(types.SET_SIMILAR_STEPS, similar_steps);
    commit(types.SET_IS_TASK_STARRED, starred);
    commit(types.SET_OPTIONAL_STEPS, optional_steps ? optional_steps : []);
    commit(types.SET_TASK_ASSOCIATE_MODEL_VERSION, model_version);
    commit(types.SET_STEPS_JSON_DATA, stepsJsonData);
    commit(types.SET_MODEL_CHOICE, model_choice);
    commit(types.SET_MAX_CYCLE_TIME, maximum_cycle_time);
    commit(types.SET_TASK_CREATED_AT, created_at);

    commit(types.SET_TASK_PARAMETERS, taskParameters);
    // commit(types.SET_TASK_STATIC_IMAGE_ID, annotation_image_id);
    dispatch('setTaskStaticImage', annotation_image_id);

    const tempSAT = stepsAvgTime === null ? {} : stepsAvgTime;
    if (Object.keys(tempSAT).length > 0) {
      commit(types.SET_STEP_TIME, tempSAT);
      commit(types.SET_IS_STEP_TIME_EXIST, true);
    }
    const tempSST = sub_step_times === null ? {} : sub_step_times;
    dispatch('setSubStepTimesJsonData', tempSST);
    if (!translation_name || isTranslationNotExist(translation)) {
      dispatch('resetTranslationState');
    } else {
      commit(types.SET_TRANSLATION_DETAILS, translation);
      commit(types.SET_TRANSLATION_LANGUAGE, translation_name);
    }
  },

  setTaskStaticImage({ commit }, annotation_image_id) {
    commit(types.SET_TASK_STATIC_IMAGE_ID, annotation_image_id);
  },

  setStepsJsonData({ commit }, payload) {
    if (payload) commit(types.SET_STEPS_JSON_DATA, payload);
  },
    async getTaskObjects({ commit }, taskId) {
    const [error, data] = await TaskService.fetchTaskObjects(taskId, false);
    if (error) return;
    for (const item of data) {
      if (!validateColor(item.outline_color)) {
        item.outline_color = getRandomColor();
        const { id: objectId, ...objectData } = item;
        const [error] = await TaskService.updateTaskObject(objectId, objectData);
        
        if (error) {
          console.log('Error while updating object', error);
          return;
        }
      }
    }
    const regionsObjects = data.filter((t) => t.is_static === true);
    const idsMap = getIdToObjectMapping(data);
    const objMap = getObjectToIdMapping(data);
    commit(types.SET_TASK_OBJECTS, data);
    commit(types.SET_TASK_STATIC_OBJECTS, regionsObjects);
    commit(types.SET_ID_TO_OBJ_MAPPING, { ...idsMap });
    commit(types.SET_OBJ_TO_ID_MAPPING, { ...objMap });
  },

  // async getTaskPlaneObjects({ commit }, taskId) {
  //   const [error, data] = await TaskService.fetchTaskPlaneObjects(taskId, false);
  //   if (error) return;
  //   commit(types.SET_PLANE_OBJECTS, data);
  // },

  async addTaskObject({ commit, state }, payload) {
    const [error, data] = await TaskService.addTaskObject(payload);
    console.log(data);
    if (error) {
      console.log('error while updating object', error);
      return;
    }
    commit(types.SET_TASK_OBJECTS, [...state.taskObjects, data]);
  },

  async addPlaneObject({ commit }, payload) {
    const [error, data] = await TaskService.addPlaneObject(payload);
    console.log(data);
    if (error) {
      console.log('error while updating object', error);
      return;
    }
  },

  async deletePlaneObject({ commit }, id) {
    const [error] = await TaskService.deletePlaneObject(id);
    if (error) {
      console.log('error while updating object', error);
      return;
    }
  },

  async createNewPlane({ commit }, payload) {
    const [error, data] = await TaskService.createNewPlane(payload);
    if (error) {
      console.log('error while updating object', error);
      return;
    }
  },

  async updateTaskObject({ commit, state }, payload) {
    const { objectId, objectData } = payload;

    const [error] = await TaskService.updateTaskObject(objectId, objectData);
    if (error) {
      console.log('error while updating object', error);
      return;
    }
    const temp = [...state.taskObjects];
    const objectIndex = temp.findIndex((obj) => obj.id === objectId);
    temp[objectIndex] = objectData;
    commit(types.SET_TASK_OBJECTS, temp);
  },

  async deleteTaskObject({ commit }, objectId) {
    const [error] = await TaskService.deleteTaskObject(objectId);
    if (error) {
      console.log('error while updating object', error);
      return;
    }
    const temp = [...state.taskObjects].filter((obj) => obj.id !== objectId);
    commit(types.SET_TASK_OBJECTS, temp);
  },

  async getTaskObjectRules({ commit }, taskId) {
    const [error, data] = await TaskService.fetchTaskObjectRules(taskId, false);
    if (error) return;
    commit(types.SET_TASK_OBJECT_RULES, data);
  },

  setTaskNameAndProcess({ commit }, payload) {
    const { name, processes } = getTaskNameAndProcess(payload);
    commit(types.SET_IS_MULTI_PROCESS_TASK, processes?.length > 1);
    commit(types.SET_PROCESS_TO_STEP_LIST_MAP, getProcessToStepsMap(processes));
    commit(types.SET_TASK_NAME, name);
    commit(types.SET_TASK_PROCESSES, processes);
  },

  setIndexToStepsMapping({ commit, state }) {
    const { taskProcesses } = state;
    const indexToStepMapping = getIndexToStepMapping(taskProcesses);
    commit(types.SET_INDEX_TO_STEPS_MAPPING, indexToStepMapping);
  },

  setStepsToIndexMapping({ commit, state }) {
    const { taskProcesses } = state;
    const stepsToIndexMapping = getStepToIndexMapping(taskProcesses);
    commit(types.SET_STEPS_TO_INDEX_MAPPING, stepsToIndexMapping);
  },

  setsubstepsToStepMapping({ commit, state }) {
    const { taskProcesses } = state;
    const substepsToStepMapping = getSubstepToStepMapping(taskProcesses);
    commit(types.SET_SUB_STEP_TO_STEP_MAPPING, substepsToStepMapping);
  },

  setTranslatedTaskName({ commit }, payload) {
    commit(types.SET_TRANSLATED_TASK_NAME, payload);
  },

  setStepToAttributesMapping({ commit, state }) {
    const { taskProcesses: processes, negativeSteps, optionalSteps } = state;
    const temp = {};
    let index = 0;
    processes.forEach((process, processIndex) => {
      process.steps.forEach((step, stepIndex) => {
        step.substeps.forEach((_, substepIndex) => {
          temp[`${processIndex}-${stepIndex}-${substepIndex}`] = {
            isNegative: negativeSteps.includes(index),
            isOptional: optionalSteps.includes(index),
            index,
          };
          index++;
        });
      });
    });
    commit(types.SET_STEP_TO_ATTRIBUTES_MAPPING, temp);
  },

  handleChangeNegativeStepChange({ commit, state }, payload) {
    const { stepToAttributesMapping: temp } = state;
    const { processIndex, stepIndex, substepIndex } = payload;
    temp[`${processIndex}-${stepIndex}-${substepIndex}`].isNegative =
      !temp[`${processIndex}-${stepIndex}-${substepIndex}`].isNegative;
    commit(types.SET_STEP_TO_ATTRIBUTES_MAPPING, temp);
  },

  handleChangeOptionalStepChange({ commit, state }, payload) {
    const { stepToAttributesMapping: temp } = state;
    const { processIndex, stepIndex, substepIndex } = payload;
    const markedStep = `${processIndex}-${stepIndex}-${substepIndex}`;
    temp[markedStep].isOptional = !temp[markedStep].isOptional;
    commit(types.SET_STEP_TO_ATTRIBUTES_MAPPING, temp);
  },

  async updateSteps({ commit, state }) {
    const {
      selectedTask,
      stepToAttributesMapping,
      negativeSteps,
      optionalSteps,
    } = state;
    let payload = {};
    const isNegUpdated =
      updatedAttributeSteps(stepToAttributesMapping, 'isNegative') !==
      negativeSteps.sort().join(',');
    const isOptUpdated =
      updatedAttributeSteps(stepToAttributesMapping, 'isOptional') !==
      optionalSteps.sort().join(',');

    if (isNegUpdated) {
      var updateNegativeSteps = Object.values(stepToAttributesMapping)
        .filter((e) => e.isNegative)
        .map((e) => e.index);
      payload['negativeSteps'] = updateNegativeSteps;
    }
    if (isOptUpdated) {
      var updateOptionalSteps = Object.values(stepToAttributesMapping)
        .filter((e) => e.isOptional)
        .map((e) => e.index);
      payload['optional_steps'] = updateOptionalSteps;
    }

    const [error] = await TaskService.updateTask(selectedTask, {
      ...payload,
    });
    if (error) {
      console.log({ error });
      return;
    }
    isOptUpdated && commit(types.SET_OPTIONAL_STEPS, updateOptionalSteps);
    isNegUpdated && commit(types.SET_NEGATIVE_STEPS, updateNegativeSteps);
  },

  async deleteTask({ dispatch }, taskId) {
    const [error] = await TaskService.deleteTask(taskId);
    if (error) {
      console.log({ error });
      return;
    }
    await dispatch('clearTaskDetails');
    await dispatch('getAllTasks');
  },

  setSelectedLanguage({ commit }, payload) {
    commit(types.SET_SELECTED_LANGUAGE, payload);
  },

  resetState({ commit, dispatch }) {
    commit(types.SET_PROCESS, []);
    commit(types.SET_NEW_TASK, '');
    commit(types.SET_UPDATED_JSON_PAYLOAD, []);
    dispatch('clearTaskDetails');
    dispatch('resetProcessState');
  },

  setNewTask({ commit }, payload) {
    commit(types.SET_NEW_TASK, payload);
  },

  setTaskUpdateConfirmModal({ commit }, payload) {
    commit(types.SET_TASK_UPDATE_CONFIRM_MODAL, payload);
  },

  setProcesses({ commit }, payload) {
    commit(types.SET_PROCESS, payload);
  },

  setProcessesTranslation({ commit }, payload) {
    commit(types.SET_PROCESS_TRANSLATION, payload);
  },

  addProcess({ commit, state }) {
    const processes = [...state.processes];
    processes.push({
      name: '',
      steps: [],
    });
    commit(types.SET_PROCESS, processes);
  },

  updateProcess({ commit, state }, payload) {
    const { processIndex, process } = payload;
    const processes = [...state.processes];
    processes[processIndex]['name'] = process;
    commit(types.SET_PROCESS, processes);
  },

  updateProcessTranslation({ commit, state }, payload) {
    const { processIndex, process } = payload;
    const processes = [...state.processesTranslation];
    processes[processIndex]['name'] = process;
    commit(types.SET_PROCESS_TRANSLATION, processes);
  },

  removeProcess({ commit, state }, payload) {
    const { processIndex } = payload;
    const processes = state.processes.filter(
      (_, index) => index !== processIndex
    );

    commit(types.SET_PROCESS, processes);
  },

  addStep({ commit, state }, payload) {
    const { processIndex } = payload;
    const processes = [...state.processes];
    const processSteps = processes[processIndex]['steps'];
    if (!processSteps.at(-1)?.name && !!processSteps.length) {
      toast.info('Please finish the last step first!', {
        timeout: 2000,
      });
      return;
    }
    processes[processIndex]['steps'].push({
      name: '',
      substeps: [],
    });

    commit(types.SET_PROCESS, processes);
  },

  updateStep({ commit, state }, payload) {
    const { step, stepIndex, processIndex } = payload;
    const processes = [...state.processes];
    const processSteps = [...processes[processIndex]['steps']];
    processSteps[stepIndex]['name'] = step;
    processes[processIndex]['steps'] = processSteps;
    commit(types.SET_PROCESS, processes);
  },

  updateStepTranslation({ commit, state }, payload) {
    const { step, stepIndex, processIndex } = payload;
    const processes = [...state.processesTranslation];
    const processSteps = [...processes[processIndex]['steps']];
    processSteps[stepIndex]['name'] = step;
    processes[processIndex]['steps'] = processSteps;
    commit(types.SET_PROCESS_TRANSLATION, processes);
  },

  removeStep({ commit, state }, payload) {
    const { stepIndex, processIndex } = payload;
    const processes = [...state.processes];
    const steps = processes[processIndex]['steps'].filter(
      (_, index) => index !== stepIndex
    );
    processes[processIndex]['steps'] = steps;
    commit(types.SET_PROCESS, processes);
  },

  moveStep({ commit, state }, payload) {
    const { processIndex, stepIndex, direction } = payload;
    const stepsLastIndex = state.processes[processIndex]['steps'].length - 1;
    if (
      (stepIndex === 0 && direction === 'up') ||
      (stepIndex === stepsLastIndex && direction === 'down')
    )
      return;

    const destinationIndex = getDestinationIndex(stepIndex, direction);
    const processes = [...state.processes];
    const steps = [...processes[processIndex]['steps']];
    const temp = steps[stepIndex];
    steps[stepIndex] = steps[destinationIndex];
    steps[destinationIndex] = temp;
    processes[processIndex]['steps'] = steps;
    commit(types.SET_PROCESS, processes);
  },

  addSubstep({ commit, state }, payload) {
    const { stepIndex, processIndex } = payload;
    const processes = [...state.processes];
    const substeps = processes[processIndex]['steps'][stepIndex]['substeps'];
    if (!substeps.at(-1) && !!substeps.length) {
      toast.info('Please finish the last sub-step first!', {
        timeout: 2000,
      });
      return;
    }
    processes[processIndex]['steps'][stepIndex]['substeps'].push('');
    commit(types.SET_PROCESS, processes);
  },

  updateSubstep({ commit, state }, payload) {
    const { substep, substepIndex, stepIndex, processIndex } = payload;
    const processes = [...state.processes];
    processes[processIndex]['steps'][stepIndex]['substeps'][substepIndex] =
      substep;
    commit(types.SET_PROCESS, processes);
  },

  updateSubstepTranslation({ commit, state }, payload) {
    const { substep, substepIndex, stepIndex, processIndex } = payload;
    const processes = [...state.processesTranslation];
    processes[processIndex]['steps'][stepIndex]['substeps'][substepIndex] =
      substep;
    commit(types.SET_PROCESS_TRANSLATION, processes);
  },

  removeSubstep({ commit, state }, payload) {
    const { substepIndex, stepIndex, processIndex } = payload;
    const processes = [...state.processes];
    processes[processIndex]['steps'][stepIndex]['substeps'] = processes[
      processIndex
    ]['steps'][stepIndex]['substeps'].filter(
      (_, index) => index !== substepIndex
    );
    commit(types.SET_PROCESS, processes);
  },

  moveSubstep({ commit, state }, payload) {
    const { processIndex, stepIndex, substepIndex, direction } = payload;
    const substepsLastIndex =
      state.processes[processIndex]['steps'][stepIndex]['substeps'].length - 1;
    if (
      (substepIndex === 0 && direction === 'up') ||
      (substepIndex === substepsLastIndex && direction === 'down')
    )
      return;

    const destinationIndex = getDestinationIndex(substepIndex, direction);
    const processes = [...state.processes];
    const substeps = [
      ...processes[processIndex]['steps'][stepIndex]['substeps'],
    ];

    const temp = substeps[substepIndex];
    substeps[substepIndex] = substeps[destinationIndex];
    substeps[destinationIndex] = temp;
    processes[processIndex]['steps'][stepIndex]['substeps'] = substeps;
    commit(types.SET_PROCESS, processes);
  },

  resetTranslationState({ commit }) {
    commit(types.SET_TRANSLATED_TASK_NAME, '');
    commit(types.SET_TRANSLATION_DETAILS, '');
    commit(types.SET_TRANSLATION_LANGUAGE, '');
  },

  resetProcessState({ commit }) {
    commit(types.SET_PROCESS_TRANSLATION, []);
    commit(types.SET_PROCESS, []);
    commit(types.SET_NEW_TASK, '');
    commit(types.SET_TASK_STRING, '');
    commit(types.SET_IS_TASK_EDIT, false);
    commit(types.SET_UPDATED_JSON_PAYLOAD, []);
    commit(types.SET_MERGE_STEPS_DICTIONARY, {});
    commit(types.SET_CURRENT_EDIT_MODE, '');
  },

  cancelTranslation({ commit }) {
    commit(types.SET_IS_TRANSLATION_EDIT, false);
    commit(types.SET_IS_TRANSLATION, false);
  },

  closeProjectorModal({ commit }) {
    commit(types.SET_SHOW_PROJECTOR_REGION_MODAL, false);
  },

  upsertTranslation({ commit, state, dispatch }) {
    const { translationDetails } = state;
    if (translationDetails) {
      const { name, processes } = getTaskNameAndProcess(translationDetails);
      dispatch('changeSelectedLanguage', 'English');
      commit(types.SET_TRANSLATED_TASK_NAME, name);
      commit(types.SET_PROCESS_TRANSLATION, processes);
      commit(types.SET_IS_TRANSLATION_EDIT, true);
    } else {
      const translation = initializeTranslation(state);
      commit(types.SET_PROCESS_TRANSLATION, translation);
    }
    commit(types.SET_IS_TRANSLATION, true);
  },

  async addTranslation({ commit, state, dispatch }, language) {
    const { processesTranslation, selectedTask, translatedTaskName } = state;
    if (checkIfEmpty(processesTranslation)) return;
    if (isDuplicateProcess(processesTranslation)) return;
    if (isDuplicateStep(processesTranslation)) return;
    if (isDuplicateSubstep(processesTranslation)) return;
    if (isStepAndSubstepSame(processesTranslation)) return;
    commit(types.SET_IS_ADDING_TRANSLATION, true);
    const translatedTaskString = getTaskString(
      processesTranslation,
      translatedTaskName
    );
    const translationName = language;
    const dataPayload = {
      translation: `${translatedTaskString}`,
      translation_name: translationName,
    };
    const [error, data] = await TaskService.updateTask(
      selectedTask,
      dataPayload,
      false
    );
    commit(types.SET_IS_ADDING_TRANSLATION, false);
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_TRANSLATION_DETAILS, translatedTaskString);
    commit(types.SET_TRANSLATION_LANGUAGE, language);
    commit(types.SET_TRANSLATED_TASK_NAME, translationName);

    dispatch('changeSelectedLanguage', language);
    dispatch('cancelTranslation');
  },

  async deleteTranslation({ commit, state, dispatch }) {
    commit(types.SET_IS_DELETING_TRANSLATION, true);
    const { selectedTask } = state;
    const [error, data] = await TaskService.updateTask(
      selectedTask,
      { translation: 'null', translation_name: '' },
      false
    );
    commit(types.SET_IS_DELETING_TRANSLATION, false);
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_IS_DELETING_TRANSLATION, false);
    commit(types.SET_IS_TRANSLATION, false);
    commit(types.SET_IS_TRANSLATION_EDIT, false);
    dispatch('resetTranslationState');
  },

  changeSelectedLanguage({ commit, state, dispatch }, language) {
    const { taskDetails, translationDetails } = state;
    const details = language === 'English' ? taskDetails : translationDetails;
    commit(types.SET_SELECTED_LANGUAGE, language);
    dispatch('setTaskNameAndProcess', details);
  },

  setCurrentEditMode({ commit }, payload) {
    commit(types.SET_CURRENT_EDIT_MODE, payload);
  },

  upsertTask({ commit, state, dispatch }) {
    const { newTask, processes, isTaskEdit } = state;
    if (validateForSpecialCharacters(state)) return;
    if (!isTaskEdit && checkIfTaskExist(state)) return;
    if (checkIfEmpty(processes)) return;
    if (checkIfSubstepsExists(processes)) return;
    if (isDuplicateProcess(processes)) return;
    if (isStepAndSubstepSame(processes)) return;
    if (isDuplicateStep(processes)) return;
    if (isDuplicateSubstep(processes)) return;
    if (hasNoProcess(processes)) return;
    if (hasNoSteps(processes)) return;
    if (hasMorethanTwoProcess(processes)) return;
    commit(types.SET_TASK_STRING, getTaskString(processes, newTask));
    if (isTaskEdit) startTaskModification(state, commit);
    else dispatch('addTask');
    return true;
  },

  async addTask({ state, commit, dispatch }) {
    commit(types.SET_IS_ADDING_TASK, true);
    const { newTask, taskString } = state;
    const payload = {
      taskName: newTask,
      task_detail: taskString,
      stepsCount: getStepsCount(state),
      Organization: localStorage.getItem('organization'),
    };

    const [error, data] = await TaskService.addTask(payload, false);
    commit(types.SET_IS_ADDING_TASK, false);
    if (error) {
      console.log({ error });
      return;
    }
    const tasks = [...state.allTasks, { ...data }];
    dispatch('resetProcessState');
    commit('setTaskId', data.id);
    commit(types.SET_TASKS, tasks);
    commit(types.SET_SELECTED_TASK, data.id);
  },

  async editTask({ state, commit }) {
    commit(types.SET_IS_FETCHING_TASK_STATUS, true);
    const { taskDetails, selectedTask, taskName } = state;

    const taskStatus = TaskService.fetchTaskStatus(selectedTask, false);
    const devicesRunningSelectedTask =
      DeviceService.getDevicesRunningSelectedTask(false, {
        task: selectedTask,
      });

    const [res, _res] = await Promise.all([
      taskStatus,
      devicesRunningSelectedTask,
    ]);
    const [error, data] = res;
    const [_error, devices] = _res;

    commit(types.SET_IS_FETCHING_TASK_STATUS, false);
    if (error || _error) {
      console.log({ error, _error });
      return;
    }
    if (isTaskBeingPerformed(devices, taskName)) return;
    if (isTaskBeingTrained(data, taskName)) return;

    commit(types.SET_IS_TASK_EDIT, true);
    const { name, processes } = getTaskNameAndProcess(taskDetails);
    commit(types.SET_NEW_TASK, name);
    commit(types.SET_PROCESS, processes);
    commit(types.SET_CURRENT_EDIT_MODE, modes.addDelete);
    commit(types.SET_PREV_STEPS_JSON, getPrevStepsJson(processes));
  },

  updateTask({ state, commit, dispatch }, oldTaskDetails) {
    return new Promise(async (resolve) => {
      commit(types.SET_IS_ADDING_TASK, true);
      const payload = getTaskUpdatePayload(state, oldTaskDetails);

      const [error] = await TaskService.updateTask(
        state.selectedTask,
        payload,
        false
      );
      sendTaskUpdateNotification(state, payload, oldTaskDetails);

      commit(types.SET_IS_ADDING_TASK, false);
      if (error) {
        console.log({ error });
        return;
      }
      commit(types.SET_TASK_DETAILS, payload.task_detail);
      commit(types.SET_TASK_NAME, payload.taskName);
      commit(types.SET_SELECTED_LANGUAGE, 'English');
      commit(types.SET_TASKS, getUpdatedTasksList(state));
      if (oldTaskDetails !== payload.task_detail)
        dispatch('setSubStepTimesJsonData', payload.sub_step_times);
      dispatch('updateTaskParametersFields', payload);
      dispatch('resetProcessState');
      resolve();
    });
  },

  updateTaskParametersFields({ commit, state }, payload) {
    const updatedParams = {};
    const taksParamsKeys = [
      'required_steps_for_start',
      'required_steps_for_end',
    ];
    const payloadKeys = Object.keys(payload);
    taksParamsKeys.forEach((key) => {
      if (payloadKeys.includes(key))
        updatedParams[key] = JSON.parse(payload[key]);
    });
    if (!Object.keys(updatedParams)?.length) return;
    const { taskParameters } = state;
    let { telemetryParams, trainingParams } = taskParameters;
    telemetryParams = { ...telemetryParams, ...updatedParams };
    const updatedTaskParameters = {
      telemetryParams: deepClone(telemetryParams),
      trainingParams: deepClone(trainingParams),
    };
    commit(types.SET_TASK_PARAMETERS, updatedTaskParameters);
    const taskJson = getUpdatedTaskJson(state);
    commit(types.SET_TASK_JSON, taskJson);
  },

  updateTaskFields({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      commit(types.SET_IS_UPDATING_TASK_FIELDS, true);
      const [error] = await TaskService.updateTask(
        state.selectedTask,
        payload,
        false
      );

      commit(types.SET_IS_UPDATING_TASK_FIELDS, false);
      if (error) {
        console.log({ error });
        reject();
      }
      resolve();
    });
  },

  updateSimilarStepsModalState({ commit, dispatch }, payload) {
    dispatch('changeSelectedLanguage', 'English');
    commit(types.SET_SHOW_SIMILAR_STEPS_MODAL, payload);
  },

  async updateSimilarSteps({ commit, state }, similarStepsJson) {
    commit(types.SET_IS_UPDATING_SIMILAR_STEPS, true);
    const payload = {
      similarSteps: similarStepsJson,
    };
    const [error] = await TaskService.updateTask(
      state.selectedTask,
      payload,
      false
    );
    await updateSimilarStepsFileS3(state, similarStepsJson);
    commit(types.SET_IS_UPDATING_SIMILAR_STEPS, false);
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_SIMILAR_STEPS, JSON.parse(similarStepsJson));
    commit(types.SET_SHOW_SIMILAR_STEPS_MODAL, false);
  },

  async updateTaskJson({ commit, state }, payload) {
    commit(types.SET_IS_UPDATING_TASK_PARAMS, true);
    const { taskParams, taskJson, substepTimes } = payload;
    const updatedData = {
      ...taskParams,
      sub_step_times: substepTimes,
    };
    const [error] = await TaskService.updateTask(
      state.selectedTask,
      updatedData,
      false
    );
    commit(types.SET_IS_UPDATING_TASK_PARAMS, false);
    if (error) {
      console.log({ error });
      toast.error('Error occurred in saving your changes!');
      return;
    }
    toast.success('Your changes have been saved!');
    commit(types.SET_TASK_JSON, taskJson);
    commit(types.SET_MODEL_CHOICE, taskParams.model_choice);

    return true;
  },

  async createTaskObjectRule({ commit }, payload) {
    commit(types.SET_IS_SAVING_OBJECT_DETECTION_RULE, true);
    const { taskObjectRules, selectedTask } = state;
    console.log('create:', payload, taskObjectRules);
    const [error, data] = await TaskService.createTaskObjectRules(
      selectedTask,
      payload,
      false
    );
    commit(types.SET_IS_SAVING_OBJECT_DETECTION_RULE, false);
    if (error) {
      console.log(error);
      return 'error';
    } else {
      commit(types.SET_TASK_OBJECT_RULES, [...taskObjectRules, ...data]);
      return 1;
    }
  },

  async deleteTaskObjectRules({ commit }, params) {
    commit(types.SET_IS_SAVING_OBJECT_DETECTION_RULE, true);
    const { taskObjectRules } = state;
    const [error, res] = await TaskService.deleteTaskObjectRules(params, false);
    commit(types.SET_IS_SAVING_OBJECT_DETECTION_RULE, false);
    if (error) {
      console.log(error);
      return 'error';
    } else {
      let ids = params.ids.split(',');
      let remaining_objects = taskObjectRules.filter(
        (obj) =>
          !(
            obj.step == params.step &&
            ids.includes(
              obj?.non_static_object?.toString() ||
                obj?.static_object?.toString()
            )
          )
      );

      commit(types.SET_TASK_OBJECT_RULES, [...remaining_objects]);
      return 1;
    }
  },

  async updateTaskObjectDetectionRule({ commit, state }, payload) {
    const { id: ruleId, ...resData } = payload;
    const { taskObjectRules } = state;
    commit(types.SET_IS_SAVING_OBJECT_DETECTION_RULE, true);
    const [error, res] = await TaskService.updateTaskObjectDetectionRule(
      ruleId,
      resData,
      false
    );
    commit(types.SET_IS_SAVING_OBJECT_DETECTION_RULE, false);
    if (error) {
      console.log(error);
      return 'error';
    } else {
      const updatedRules = updateTaskObjectRules(taskObjectRules, payload);
      commit(types.SET_TASK_OBJECT_RULES, updatedRules);
      return 1;
    }
  },

  createStepsJsonData({ state, dispatch }, payload) {
    return new Promise(async (resolve) => {
      const json = JSON.stringify(payload);
      const [error, data] = await TaskService.updateTask(
        state.selectedTask,
        { steps_data_json: json },
        false
      );
      if (error) {
        console.log('error in create/update steps json');
        resolve(false);
      }
      const stepsJsonData = JSON.parse(data.steps_data_json);
      dispatch('setStepsJsonData', stepsJsonData);
      resolve(true);
    });
  },

  changeAvgCycleTime({ commit, state }, payload) {
    const { stepTime } = state;
    stepTime.avgCycleTime = payload;
    commit(types.SET_STEP_TIME, { ...stepTime });
  },

  changeStepAvgTime({ commit, state }, payload) {
    const { stepTime } = state;
    const { step, time } = payload;
    stepTime.stepAvgTime = { ...stepTime.stepAvgTime, [step]: time };
    commit(types.SET_STEP_TIME, { ...stepTime });
  },

  async addStepTime({ commit }) {
    let { stepTime } = state;
    stepTime = convertStepTimeValuesToNumber(stepTime);
    commit(types.SET_IS_UPDATING_STEP_TIME, true);
    const payload = { stepsAvgTime: stepTime };
    const [error] = await TaskService.updateTask(
      state.selectedTask,
      payload,
      false
    );
    commit(types.SET_IS_UPDATING_STEP_TIME, false);
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_SHOW_STEP_TIME_MODAL, false);
    commit(types.SET_IS_STEP_TIME_EXIST, true);
  },

  changeSubStepTimes({ state, commit }, payload) {
    let { subStepTimesJson } = state;
    const { step, time } = payload;
    subStepTimesJson = {
      ...subStepTimesJson,
      [step]: time,
    };
    commit(types.SET_SUB_STEP_TIMES_JSON_DATA, subStepTimesJson);
  },

  setSubStepTimesJsonData({ commit, state }, payload) {
    // get stepIndex to name map on whole json
    const json = setSubStepTimesJsonFile(state, payload);
    commit(types.SET_SUB_STEP_TIMES_JSON_DATA, json);
  },

  async deleteStepsTime({ commit, dispatch }) {
    commit(types.SET_IS_UPDATING_STEP_TIME, true);
    const payload = { stepsAvgTime: {} };
    const [error] = await TaskService.updateTask(
      state.selectedTask,
      payload,
      false
    );
    commit(types.SET_IS_UPDATING_STEP_TIME, false);
    if (error) {
      console.log({ error });
      return;
    }
    dispatch('resetStepTimeState');
  },

  resetStepTimeState({ commit }) {
    commit(types.SET_SHOW_STEP_TIME_MODAL, false);
    commit(types.SET_IS_STEP_TIME_EXIST, false);
    commit(types.SET_STEP_TIME, { ...initialStepTime });
  },

  moveToMergeStepPhase({ commit, state }) {
    const { processes, updatedJsonPayload, currentEditMode } = state;
    const jsonForRenamePhase = getTaskUpdateJson(processes, currentEditMode);
    const tempUpdatedJson = [...updatedJsonPayload, jsonForRenamePhase];
    commit(types.SET_CURRENT_EDIT_MODE, modes.merge);
    commit(types.SET_UPDATED_JSON_PAYLOAD, tempUpdatedJson);
    commit(types.SET_RES_JSON_FOR_RENAME_PHASE, deepClone(processes));
  },

  moveToRenamePhaseFromMerge({ commit, state }) {
    const { resJsonForRenamePhase: json, updatedJsonPayload } = state;
    const tempUpdatedJson = updatedJsonPayload.filter(
      (json) => json.editMode === modes.addDelete
    );
    commit(types.SET_UPDATED_JSON_PAYLOAD, tempUpdatedJson);
    commit(types.SET_CURRENT_EDIT_MODE, modes.rename);
    commit(types.SET_MERGE_STEPS_DICTIONARY, {});
    if (json.length !== 0) commit(types.SET_PROCESS, json);
  },

  moveToRenamePhaseFromAddDelete({ commit, state }) {
    const { processes, updatedJsonPayload, currentEditMode } = state;
    const jsonForAddDeletePhase = getTaskUpdateJson(processes, currentEditMode);
    commit(types.SET_CURRENT_EDIT_MODE, modes.rename);
    commit(types.SET_UPDATED_JSON_PAYLOAD, [
      ...updatedJsonPayload,
      jsonForAddDeletePhase,
    ]);
    commit(types.SET_RES_JSON_FOR_ADD_DELETE_PHASE, deepClone(processes));
  },

  moveToAddDeletePhase({ commit, state }) {
    const { resJsonForAddDeletePhase: json } = state;
    commit(types.SET_CURRENT_EDIT_MODE, modes.addDelete);
    commit(types.SET_UPDATED_JSON_PAYLOAD, []);
    if (json.length !== 0) commit(types.SET_PROCESS, json);
  },

  setTaskParameters({ commit }, payload) {
    commit(types.SET_TASK_PARAMETERS, payload);
  },

  showModal({ commit }, payload) {
    const { modalType, value } = payload;
    commit(modalType, value);
  },

  toggleLoading({ commit }, payload) {
    const { name, value } = payload;
    commit(name, value);
  },

  setUpdateTaskFromAdmin({ commit }, payload) {
    commit(types.SET_UPDATE_TASK_FROM_ADMIN, payload);
  },

  async getTaskStaticObjectsList({ commit }, id) {
    const [error, data] = await TaskService.getTaskStaticObjectList(id);

    if (error) return;
    commit(types.SET_TASK_STATIC_OBJECTS, data);
  },

  async getTaskPlaneObjectsList({ commit, state }) {
    const [error, data] = await TaskService.fetchTaskPlaneObjects(
      state.selectedTask
    );
    if (error) return;
    commit(types.SET_PLANE_OBJECTS, data);
  },

  setTaskImageMarkingUrl({ commit }, url) {
    commit(types.SET_TASK_REGION_MARK_IMAGE_URL, url);
  },

  setMergeStepsDict({ commit }, payload) {
    commit(types.SET_MERGE_STEPS_DICTIONARY, payload);
  },

  setStepTranslations({ commit, state }, language) {
    const { translationLanguage, taskDetails, translationDetails } = state;

    let stepsTranslationMapping = {};
    if (language === translationLanguage) {
      stepsTranslationMapping = getTranslatedSteps(
        taskDetails,
        translationDetails
      );
    } else {
      stepsTranslationMapping = getTranslatedSteps(taskDetails);
    }
    commit(types.SET_STEPS_TRANSLATION_MAPPING, stepsTranslationMapping);
  },

  async updateTaskSelectedModal({ state, commit }, payload) {
    const { selectedTask } = state;
    commit(types.SET_IS_UPDATING_ASSOCIATE_MODEL_VERSION, true);
    const [error, _] = await TaskService.updateTask(selectedTask, {
      model_version: payload,
    });
    if (error) {
      toast.error('error occurred in updating the model on Task!');
      return false;
    }
    toast.info(
      'Please restart the task for any version changes to take effect.'
    );
    commit(types.SET_IS_UPDATING_ASSOCIATE_MODEL_VERSION, false);
    commit(types.SET_TASK_ASSOCIATE_MODEL_VERSION, payload);
    commit(types.SHOW_MODEL_VERSIONS_MODAL, false);
    return true;
  },
  changeAnnotationObjectTab({ commit }, payload) {
    commit(types.CURR_OBJ_ANNOTATION_MODE, payload);
  },

  setAnnotationObjectJson({ commit, state }) {
    const json = initAnnotationObjectsJson(state);
    commit(types.SET_ANNOTATION_OBJ_JSON, json);
  },

  handleShowAnnotationObjectModal({ dispatch }) {
    dispatch('setAnnotationObjectJson');
    dispatch('showModal', {
      modalType: types.SHOW_ANNOTATION_OBJECTS_MODAL,
      value: true,
    });
  },

  changeAnnotationStepsObjects({ commit, state }, payload) {
    const { stepIndex, key, value } = payload;
    const { currentAnnotationObjectMode, annotationObjectJson } = state;

    const temp = { ...annotationObjectJson };
    const currentmode = annotationObjectJson[currentAnnotationObjectMode];
    currentmode[stepIndex][key] = value;
    commit(types.SET_ANNOTATION_OBJ_JSON, temp);
  },

  setObjectWarning({ commit, state }, payload) {
    const { step, err } = payload;
    const { objectWarnings } = state;
    commit(types.SET_HAS_OBJECT_WARNING, { ...objectWarnings, [step]: err });
  },

  // setStepTranslations({ commit, state }, language) {
  //   const { translationLanguage, taskDetails, translationDetails } = state;

  //   let stepsTranslationMapping = {};
  //   if (language === translationLanguage) {
  //     stepsTranslationMapping = getTranslatedSteps(
  //       taskDetails,
  //       translationDetails
  //     );
  //   } else {
  //     stepsTranslationMapping = getTranslatedSteps(taskDetails);
  //   }
  //   commit(types.SET_STEPS_TRANSLATION_MAPPING, stepsTranslationMapping);
  // },

  setStepsAssociationIds({ commit, state, rootGetters }) {
    const { taskObjects, taskObjectRules, optionalSteps, negativeSteps } =
      state;
    const { associateBarcodes } = rootGetters;
    const tempIds = new Set();
    taskObjects
      .filter((obj) => obj.step_id !== null)
      .forEach((o) => tempIds.add(o.step_id));
    taskObjectRules.forEach((rule) => tempIds.add(rule.step));
    associateBarcodes.forEach((barcode) => tempIds.add(barcode.step_index));
    optionalSteps.forEach((optional_step) => tempIds.add(optional_step));
    negativeSteps.forEach((neg_step) => tempIds.add(neg_step));
    commit(types.SET_STEPS_ASSOCIATION_IDS, tempIds);
  },
  async updateTaskModelVersion({ state, commit }, payload) {
    const { selectedTask } = state;
    const { id, model_type } = payload;
    commit(types.SET_IS_UPDATING_ASSOCIATE_MODEL_VERSION, true);
    const [error, _] = await TaskService.updateTask(selectedTask, {
      model_version: id,
      model_type,
    });
    if (error) {
      toast.error('error occurred in updating the model on Task!');
      return false;
    }
    toast.info(
      'Please restart the task for any version changes to take effect.'
    );
    commit(types.SET_IS_UPDATING_ASSOCIATE_MODEL_VERSION, false);
    commit(types.SET_TASK_ASSOCIATE_MODEL_VERSION, id);
    commit(types.SHOW_MODEL_VERSIONS_MODAL, false);
    return true;
  },

  async get3dSupportInfo({ commit }, taskId) {
    let is3dSupport = false;
    var [error, data] = await DepthRegionService.isTaskSupport3D(taskId);
    if (error) {
      is3dSupport = false;
    }
    is3dSupport = data?.is_3d;
    commit(types.SET_IS3D_TASK, is3dSupport);
  },
  setModelChoice({ commit }, modelChoice) {
    commit(types.SET_MODEL_CHOICE, modelChoice);
  },
  setMaxCycleTime({ commit }, modelChoice) {
    commit(types.SET_MAX_CYCLE_TIME, modelChoice);
  },

  async getTaskStepsMediaObj({ commit }, taskId) {
    var [error, data] = await TaskService.getTaskStepMediaObjects(taskId);
    if (error) {
      console.log('error');
    }
    console.log('media obj dict: ', data);
    commit(types.SET_TASK_STEP_MEDIA_OBJ, data.data);
  },

  async patchTaskLastUpdate({ state }) {
    const [err, data] = await TaskService.patchTaskLastUpdate(
      state.selectedTask
    );
    if (err) {
      console.log('last update error');
    }
  },

  async createRectificationSteps({ state }, payload) {
    console.log(payload);
    const [err, data] = await TaskService.createRectificationSteps(payload);
    if (err) {
      console.log('last update error');
    }
  },

  async fetchedRectificationSteps({ commit }) {
    const [err, data] = await TaskService.fetchedRectificationSteps(
      state.selectedTask
    );
    commit(types.SET_RECTIFICATION_STEPS, data);
    if (err) {
      console.log('last update error');
    }
    return data;
  },

  async deleteRectificationStep({ commit }, payload) {
    const [err, data] = await TaskService.deleteRectificationStep(payload);
    if (err) {
      console.log('last update error');
    }
  },
};

const mutations = {
  [types.SET_MISSED_STEP_SELECTED](state, payload) {
    state.isMissedStepsSelected = payload;
  },
  [types.SET_RECTIFICATION_STEPS](state, payload) {
    state.rectificationSteps = payload;
  },
  [types.SET_MISSED_STEP_SELECTED](state, payload) {
    state.isMissedStepsSelected = payload;
  },
  [types.SET_TASKS](state, payload) {
    state.allTasks = payload;
  },
  [types.SET_TRAINED_TASKS](state, payload) {
    state.trainedTasks = payload;
  },
  [types.SET_SELECTED_TASK](state, payload) {
    state.selectedTask = payload;
  },
  [types.SET_IS3D_TASK](state, payload) {
    state.isTask3d = payload;
  },
  [types.SET_MODEL_CHOICE](state, payload) {
    state.modelChoice = payload;
  },
  [types.SET_TASK_NAME](state, payload) {
    state.taskName = payload;
  },
  [types.SET_TASK_DETAILS](state, payload) {
    state.taskDetails = payload;
  },
  [types.SET_TASK_STEPS_COUNT](state, payload) {
    state.taskStepsCount = payload;
  },
  [types.SET_IS_TASK_STARRED](state, payload) {
    state.isTaskStarred = payload;
  },
  [types.SET_IS_MULTI_PROCESS_TASK](state, payload) {
    state.isMultiProcessTask = payload;
  },
  [types.SET_PROCESS_TO_STEP_LIST_MAP](state, payload) {
    state.processToStepListMap = payload;
  },
  [types.SET_TASK_JSON](state, payload) {
    state.taskJson = payload;
  },
  [types.SET_IS_ADDING_TRANSLATION](state, payload) {
    state.isAddingTranslation = payload;
  },
  [types.SET_IS_DELETING_TRANSLATION](state, payload) {
    state.isDeletingTranslation = payload;
  },
  [types.SET_IS_TRANSLATION](state, payload) {
    state.isTranslation = payload;
  },
  [types.SET_IS_TRANSLATION_EDIT](state, payload) {
    state.isTranslationEdit = payload;
  },
  [types.SET_TRANSLATED_TASK_NAME](state, payload) {
    state.translatedTaskName = payload;
  },
  [types.SET_TRANSLATION_DETAILS](state, payload) {
    state.translationDetails = payload;
  },
  [types.SET_TRANSLATION_LANGUAGE](state, payload) {
    state.translationLanguage = payload;
  },
  [types.SET_PROCESS_TRANSLATION](state, payload) {
    state.processesTranslation = payload;
  },
  [types.SET_SELECTED_LANGUAGE](state, payload) {
    state.selectedLang = payload;
  },
  [types.SET_NEGATIVE_STEPS](state, payload) {
    state.negativeSteps = payload;
  },
  [types.SET_TASK_PROCESSES](state, payload) {
    state.taskProcesses = payload;
  },
  [types.SET_PROCESS](state, payload) {
    state.processes = payload;
  },
  [types.SET_PREV_STEPS_JSON](state, payload) {
    state.prevStepsJson = payload;
  },
  [types.SET_CURRENT_EDIT_MODE](state, payload) {
    state.currentEditMode = payload;
  },
  [types.SET_NEW_TASK](state, payload) {
    state.newTask = payload;
  },
  [types.SET_TASK_STRING](state, payload) {
    state.taskString = payload;
  },
  [types.SET_UPDATED_JSON_PAYLOAD](state, payload) {
    state.updatedJsonPayload = payload;
  },
  [types.SET_RES_JSON_FOR_ADD_DELETE_PHASE](state, payload) {
    state.resJsonForAddDeletePhase = payload;
  },
  [types.SET_TASK_UPDATE_CONFIRM_MODAL](state, payload) {
    state.taskUpdateConfirmModal = payload;
  },
  [types.SET_IS_TASK_EDIT](state, payload) {
    state.isTaskEdit = payload;
  },
  [types.SET_IS_FETCHING_TASKS](state, payload) {
    state.isFetchingTasks = payload;
  },
  [types.SET_IS_FETCHING_TASK_DETAILS](state, payload) {
    state.isFetchingTaskDetails = payload;
  },
  [types.SET_IS_FETCHING_TASK_STATUS](state, payload) {
    state.isFetchingTaskStatus = payload;
  },
  [types.SET_IS_ADDING_TASK](state, payload) {
    state.isAddingTask = payload;
  },
  [types.SET_IS_UPDATING_TASK_FIELDS](state, payload) {
    state.isUpdatingTaskFields = payload;
  },
  [types.SET_SIMILAR_STEPS](state, payload) {
    state.similarSteps = payload;
  },
  [types.SET_SHOW_SIMILAR_STEPS_MODAL](state, payload) {
    state.showSimilarStepsModal = payload;
  },
  [types.SET_IS_UPDATING_SIMILAR_STEPS](state, payload) {
    state.isUpdatingSimilarSteps = payload;
  },
  [types.SET_TASK_OBJECTS](state, payload) {
    state.taskObjects = payload;
  },
  [types.SET_TASK_OBJECT_RULES](state, payload) {
    state.taskObjectRules = payload;
  },
  [types.SET_INDEX_TO_STEPS_MAPPING](state, payload) {
    state.indexToStepsMapping = payload;
  },
  [types.SET_STEPS_TO_INDEX_MAPPING](state, payload) {
    state.stepsToIndexMapping = payload;
  },
  [types.SET_IS_SAVING_OBJECT_DETECTION_RULE](state, payload) {
    state.isSavingObjectDetectionRule = payload;
  },
  [types.SET_SHOW_STEP_TIME_MODAL](state, payload) {
    state.showStepTimeModal = payload;
  },
  [types.SET_STEP_TIME](state, payload) {
    state.stepTime = payload;
  },
  [types.SET_IS_UPDATING_STEP_TIME](state, payload) {
    state.isUpdatingStepTime = payload;
  },
  [types.SET_IS_STEP_TIME_EXIST](state, payload) {
    state.isStepTimeExist = payload;
  },
  [types.SET_SHOW_TASK_PARAMS_MODAL](state, payload) {
    state.showTaskParamsModal = payload;
  },
  [types.SET_IS_UPDATING_TASK_PARAMS](state, payload) {
    state.isUpdatingTaskParams = payload;
  },
  [types.SET_TASK_PARAMETERS](state, payload) {
    state.taskParameters = payload;
  },
  [types.SET_UPDATE_TASK_FROM_ADMIN](state, payload) {
    state.isUpdateTaskFromAdmin = payload;
  },
  [types.SET_TASK_ORGANIZATION](state, payload) {
    state.taskOrganization = payload;
  },
  [types.SET_SHOW_REGION_DEFINE_MODAL](state, payload) {
    state.showRegionDefinitionModal = payload;
  },
  [types.SET_SHOW_ASSOCIATE_REGIONS_MODAL](state, payload) {
    state.showAssociateRegionModal = payload;
  },
  [types.SET_TASK_STATIC_OBJECTS](state, payload) {
    state.taskStaticObjectsList = payload;
  },
  [types.SET_TASK_REGION_MARK_IMAGE_URL](state, payload) {
    state.taskRegionMarkingImgUrl = payload;
  },
  [types.SET_STEP_TO_ATTRIBUTES_MAPPING](state, payload) {
    state.stepToAttributesMapping = payload;
  },
  [types.SET_OPTIONAL_STEPS](state, payload) {
    state.optionalSteps = payload;
  },
  [types.SET_RES_JSON_FOR_RENAME_PHASE](state, payload) {
    state.resJsonForRenamePhase = payload;
  },
  [types.SET_MERGE_STEPS_DICTIONARY](state, payload) {
    state.mergeStepsDict = payload;
  },
  [types.SET_SUB_STEP_TO_STEP_MAPPING](state, payload) {
    state.subStepToStepMapping = payload;
  },
  [types.SET_STEPS_JSON_DATA](state, payload) {
    state.stepsJsonData = payload;
  },
  [types.SET_OBJECT_DETECTION_RULE_UPDATE_DATA](state, payload) {
    state.objectDetectionRuleUpdatedData = payload;
  },
  [types.SET_SUB_STEP_TIMES_JSON_DATA](state, payload) {
    state.subStepTimesJson = payload;
  },
  [types.SHOW_MODEL_VERSIONS_MODAL](state, payload) {
    state.showModelVersionsModal = payload;
  },
  // task model version states
  [types.SET_TASK_ASSOCIATE_MODEL_VERSION](state, payload) {
    state.taskAssociatedModelVersion = payload;
  },
  [types.SET_IS_UPDATING_ASSOCIATE_MODEL_VERSION](state, payload) {
    state.isUpdatingAssociateModelVersion = payload;
  },
  [types.SHOW_ANNOTATION_OBJECTS_MODAL](state, payload) {
    state.showAnnotationObjectsModal = payload;
  },
  [types.CURR_OBJ_ANNOTATION_MODE](state, payload) {
    state.currentAnnotationObjectMode = payload;
  },
  [types.SET_ANNOTATION_OBJ_JSON](state, payload) {
    state.annotationObjectJson = payload;
  },
  [types.SET_HAS_OBJECT_WARNING](state, payload) {
    state.objectWarnings = payload;
  },
  [types.SET_ID_TO_OBJ_MAPPING](state, payload) {
    state.idToObjectMapping = payload;
  },
  [types.SET_OBJ_TO_ID_MAPPING](state, payload) {
    state.objectToIdMapping = payload;
  },
  [types.SET_STEPS_TRANSLATION_MAPPING](state, payload) {
    state.stepsTranslationMapping = payload;
  },
  [types.SHOW_MODEL_VERSIONS_MODAL](state, payload) {
    state.showModelVersionsModal = payload;
  },
  // task model version states
  [types.SET_TASK_ASSOCIATE_MODEL_VERSION](state, payload) {
    state.taskAssociatedModelVersion = payload;
  },
  [types.SET_STEPS_ASSOCIATION_IDS](state, payload) {
    state.stepsAssociationIds = payload;
  },
  [types.SET_SHOW_OD_SETUP_MODAL](state, payload) {
    state.showODSetupModal = payload;
  },
  [types.SET_SHOW_PROJECTOR_REGION_MODAL](state, payload) {
    state.showProjectorRegionModal = payload;
  },
  [types.SET_PLANE_OBJECTS](state, payload) {
    state.planeObjectsList = payload;
  },
  [types.SET_TASK_STATIC_IMAGE_ID](state, payload) {
    state.taskStaticAnnotationImage = payload;
  },
  [types.SET_TASK_CREATED_AT](state, payload) {
    state.taskCreatedAt = payload;
  },
  [types.SET_MAX_CYCLE_TIME](state, payload) {
    state.maxCycleTime = payload;
  },
  [types.SET_SHOW_UPLOAD_MEDIA_MODAL](state, payload) {
    state.showUploadMediaModal = payload;
  },
  [types.SET_TASK_STEP_MEDIA_OBJ](state, payload) {
    state.taskStepsMediaObject = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
