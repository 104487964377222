import axios from 'axios';
import ApiHelper from './index';
import baseUrl from './baseUrl';
// import { getHostUrl } from 'src/service/httpClient';

// const URL =
//   'https://5vzjp9dxua.execute-api.us-east-2.amazonaws.com/prod/firebase_topic_subscription';

let config = {
  headers: {
    'Content-Type': 'application\\json'
    // "Access-Control-Allow-Origin" : "*",
    // "Accept": "*/*",
    // "Connection" : "keep-alive",
    // "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT"
  }
};

const hostUrl = baseUrl.prodHostUrl;

const URL =
  'https://637chban56fnlgl4hw6mlvv2740gsmuj.lambda-url.us-east-2.on.aws/';

// const Lamba_URL =
//   'https://xwhs7jwvl3.execute-api.us-east-2.amazonaws.com/Demo/send_message_to_lambda';

const subscribeToChannel = async payload => {
  try {
    payload.route = '/firebase_topic_subscription';
    const { data } = await axios.post(
      `${hostUrl}firebase/topic_subscription/`,
      payload,
      config
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
};

const unsubscribeToChannel = async payload => {
  try {
    const { data } = await axios.delete(URL, payload);
    return [null, data];
  } catch (error) {
    return [error];
  }
};

const sendMessageToRabbitMQ = async payload => {
  try {
    payload.route = '/send_message_to_lambda';
    // const Lamba_URL =
    //   'https://xwhs7jwvl3.execute-api.us-east-2.amazonaws.com/Demo/send_message_to_lambda';
    const Lamba_URL = `${hostUrl}lambda/send_message_to_lambda/`;

    const { data } = await axios.post(Lamba_URL, payload, config);
    const [error, res] = await ApiHelper.post(
      `generic/send_message_to_sqs`,
      payload
    );
    console.log(res, error);
    return [null, data];
  } catch (error) {
    return [error];
  }
};

const sendMessageToSQS = async payload => {
  try {
    const [error, res] = await ApiHelper.post(
      `generic/send_message_to_sqs`,
      payload
    );
    console.log(res, error);
    return [null, res];
  } catch (error) {
    return [error];
  }
};

export default {
  subscribeToChannel,
  unsubscribeToChannel,
  sendMessageToRabbitMQ,
  sendMessageToSQS
};
