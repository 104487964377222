import types from '../mutation-types';

const getters = {
  frameStepSize: (state) => state.frameStepSize,
  isSavingAnnoation: (state) => state.isSavingAnnoation,
  isSubstepsChanged: (state) => state.isSubstepsChanged,
  webSocketConnection: (state) => state.webSocketConnection,
  isFrameUpdated: (state) => state.isFrameUpdated,
};

const actions = {
  setFrameStepSize({ commit }, payload) {
    commit(types.SET_FRAME_STEP_SIZE, payload);
  },
  setIsSavingAnnoation({ commit }, payload) {
    commit(types.SET_IS_SAVING_ANNOATION, payload);
  },
  setIsSubstepsChanged({ commit }, payload) {
    commit(types.SET_IS_SUBSTEPS_CHANGED, payload);
  },
  setWebSocketConnection({ commit }, payload) {
    commit(types.SET_WEBSOCKET_CONNECTION, payload);
  },
  setisFrameUpdated({ commit }, payload) {
    commit(types.SET_IS_FRAME_UPDATED, payload);
  },
};

const mutations = {
  [types.SET_FRAME_STEP_SIZE](state, payload) {
    state.frameStepSize = payload;
  },
  [types.SET_IS_SAVING_ANNOATION](state, payload) {
    state.isSavingAnnoation = payload;
  },
  [types.SET_IS_SUBSTEPS_CHANGED](state, payload) {
    state.isSubstepsChanged = payload;
  },
  [types.SET_WEBSOCKET_CONNECTION](state, payload) {
    state.webSocketConnection = payload;
  },
  [types.SET_IS_FRAME_UPDATED](state, payload) {
    state.isFrameUpdated = payload;
  },

};

const state = {
  frameStepSize: 10,
  isSavingAnnoation: false,
  isSubstepsChanged: false,
  webSocketConnection: null,
  isFrameUpdated: false
};

export default {
  state,
  getters,
  actions,
  mutations,
};
