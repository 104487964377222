export default [
  {
    name: 'Home',
    to: '/user/home',
    class: 'bi bi-house-door',
    disabled: false,
  },
  // {
  //   name: 'Model Versions',
  //   to: '/user/models',
  //   class: 'bi bi-list-ol',
  //   disabled: false,
  // },
  {
    name: 'LEAN',
    to: '',
    class: '',
    disabled: false,
  },
  {
    name: 'Analytics',
    to: '/user/analytics',
    class: 'os-icon os-icon-activity',
    disabled: false,
  },
  {
    name: 'Line Analytics',
    to: '/user/line-analytics',
    class: 'os-icon os-icon-activity',
    disabled: false,
  },
  {
    name: 'Trace',
    to: '/user/trace',
    class: 'os-icon os-icon-play-circle',
    disabled: false,
  },
  // {
  //   name: 'Kaizen Copilot',
  //   to: '/user/kaizen-copilot',
  //   class: 'bi bi-chat-dots',
  //   disabled: false,
  // },
  {
    name: 'Issue Center',
    to: '/user/issue-center',
    class: 'bi bi-chat-dots',
    disabled: false,
  },
  // {
  //   name: 'Ergonomics',
  //   to: '/user/ergonomics',
  //   class: 'bi bi-person-square',
  //   disabled: false
  // },
  {
    name: 'MANAGEMENT',
    to: '',
    class: '',
    disabled: false,
  },
  {
    name: 'Devices',
    to: '/user/devices',
    class: 'os-icon os-icon-screen-smartphone',
    disabled: false,
  },
  {
    name: 'OPC',
    to: '/user/opc',
    class: 'bi bi-hdd-rack-fill',
    disabled: false,
  },
  {
    name: 'Cameras',
    to: '/user/remote-devices',
    class: 'os-icon os-icon-screen-smartphone',
    disabled: false,
  },
  // {
  //   name: 'PROFILE',
  //   to: '',
  //   class: '',
  //   disabled: false,
  // },
  {
    name: 'Users',
    to: '/user/org-users',
    class: 'bi bi-people',
    disabled: false,
  },
  {
    name: 'SETUP',
    to: '',
    class: '',
    disabled: false,
  },
  // {
  //   name: 'Add Device',
  //   to: '/user/add-device',
  //   class: 'bi bi-node-plus',
  //   disabled: false,
  // },
  {
    name: 'Define Operation',
    to: '/user/process',
    class: 'os-icon os-icon-ui-49',
    disabled: false,
  },
  {
    name: 'Record Training Set',
    to: '/user/record',
    class: 'bi bi-camera-video',
    disabled: false,
  },
  {
    name: 'Label Training Set',
    to: '/user/label-data',
    class: 'bi bi-bar-chart-steps',
    disabled: false,
  },
  {
    name: 'Train Model',
    to: '/user/train',
    class: 'os-icon os-icon-hierarchy-structure-2',
    disabled: false,
  },
  {
    name: 'Deploy Model',
    to: '/user/deploy',
    class: 'os-icon os-icon-grid-10',
    disabled: false,
  },
  // {
  //   name: 'Profile Settings',
  //   to: '/user/setting',
  //   class: 'os-icon os-icon-ui-46',
  //   disabled: false,
  // },
  // {
  //   name: 'Documentation',
  //   to: '',
  //   class: '',
  //   disabled: false,
  // },
  // {
  //   name: 'User Manuals',
  //   to: '/user/user-manuals',
  //   class: 'os-icon os-icon-file-text',
  //   disabled: false,
  // },
  // ======================= User Menu ends ===========================
  {
    name: 'Organizations',
    to: '/admin/organizations',
    class: 'bi bi-building',
  },
  {
    name: 'All Users',
    to: '/admin/users',
    class: 'bi bi-people',
  },
  {
    name: 'Roles',
    to: '/admin/roles',
    class: 'bi bi-person-lines-fill',
  },
  {
    name: 'Pending Users',
    to: '/admin/pending-users',
    class: 'bi bi-people',
  },
  {
    name: 'All Devices',
    to: '/admin/devices',
    class: 'os-icon os-icon-screen-smartphone',
  },
  {
    name: 'Archived Cycles',
    to: '/admin/archived-cycles',
    class: 'os-icon os-icon-screen-smartphone',
  },
  {
    name: 'All Operation',
    to: '/admin/tasks',
    class: 'bi bi-list-task',
  },
  {
    name: 'Updates',
    to: '/admin/updates',
    class: 'bi bi-gear-wide-connected',
  },
  {
    name: 'Instances',
    to: '/admin/instances',
    class: 'os-icon os-icon-screen-smartphone',
  },
  {
    name: 'Trained Operations',
    to: '/admin/trained-tasks',
    class: 'bi bi-list-check',
  },
  {
    name: 'Annotations',
    to: '/admin/annotations',
    class: 'bi bi-tags',
  },
  {
    name: 'Labelers',
    to: '/admin/labelers',
    class: 'bi bi-people',
  },
  {
    name: 'Tampering Requests',
    to: '/admin/tampering-requests',
    class: 'bi bi-tags',
  },
  {
    name: 'Org Model Versions',
    to: '/admin/model-versions',
    class: 'bi bi-list-ol',
    disabled: false,
  },
  {
    name: 'Model Types',
    to: '/admin/model-types',
    class: 'bi bi-list-ol',
    disabled: false,
  },
  {
    name: 'User Report',
    to: '/admin/user-report',
    class: 'bi bi-clipboard-data',
  },
  {
    name: 'Barcode',
    to: '/admin/barcode',
    class: 'bi bi-clipboard-data',
  },
  {
    name: 'AWS-Lambda Token',
    to: '/admin/aws-token',
    class: 'bi bi-clipboard-data',
  },
  {
    name: 'Feature Control',
    to: '/admin/feature-control',
    class: 'bi bi-gear-wide-connected',
  },
  {
    name: 'Event Notification',
    to: '/admin/event-notification',
    class: 'bi bi-envelope-arrow-up',
  },
  // ======================= Admin Menu ends ===========================
  {
    name: 'Operations Requested',
    to: '/labeler/tasks',
    class: 'bi bi-list-task',
  },
  {
    name: 'Profile Setting',
    to: '/labeler/setting',
    class: 'bi bi-person-lines-fill',
  },
];
